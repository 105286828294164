import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { EmailService } from "./core/controllers/email.controller";
import logo from './assets/logo.png';
import './index.css';
import { PostDecryptEmailDTOS } from "./core/models/post/decrypt.email.dtos";

const emailService = new EmailService();

function App() {
  const [email, setEmail] = useState<PostDecryptEmailDTOS | null>(null);
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const [termsAlreadyAccepted, setTermsAlreadyAccepted] = useState<boolean>(false);
  const [language, setLanguage] = useState<'en' | 'de'>('en');

  const location = useLocation();

  useEffect(() => {
    const encryptedEmailFromUrl = location.pathname.substring(1);
    if (encryptedEmailFromUrl) {
      setEmail({ encryptedEmail: encryptedEmailFromUrl });
    }

    async function getData() {
      try {
        const checkAcceptedTermsResult = await emailService.checkEmail({ encryptedEmail: encryptedEmailFromUrl });
    
        if (checkAcceptedTermsResult.ok && checkAcceptedTermsResult.value) {
          if (checkAcceptedTermsResult.value.exists) {
            setTermsAlreadyAccepted(true);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    
    getData();
  }, [location]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptedTerms(event.target.checked);
  };

  const toggleLanguage = () => {
    setLanguage(language === 'en' ? 'de' : 'en');
  };

  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex flex-col items-center justify-center flex-grow p-4">
        <div className="flex flex-col shadow-xl rounded-lg min-w-[40vw] max-w-[90%] sm:max-w-[80%] md:max-w-[70%] lg:max-w-[60%] xl:max-w-[50%] gap-4 p-4 items-center">
          <div className="flex w-full items-center justify-center">
            <img src={logo} alt="Logo" className="w-[50%]" />
          </div>

          <div className="flex flex-col mt-4">
            <div className="text-center">
              <p>
                {language === 'en' ? (
                  <>
                    In accordance with the new European Union General Data Protection Regulation (GDPR) guidelines,
                    which have come into force in Germany and across Europe, it is necessary for all our users to accept the new updated terms of use.
                    The new terms include detailed information about how we collect, use and protect your personal data,
                    and ensure greater transparency and control over your information.
                    Your acceptance is essential for us to continue offering our services securely and in compliance with data protection laws.
                    Please read the new terms carefully and click the checkbox to confirm your acceptance.
                    This step is mandatory so that we can continue using our services.
                    We appreciate your understanding and cooperation.
                  </>
                ) : (
                  <>
                    Gemäß den neuen Datenschutz-Grundverordnung (DSGVO) der Europäischen Union, die in Deutschland und ganz Europa in Kraft getreten sind, ist es notwendig, dass alle unsere Nutzer die neuen aktualisierten Nutzungsbedingungen akzeptieren.
                    Die neuen Bedingungen enthalten detaillierte Informationen darüber, wie wir Ihre personenbezogenen Daten erheben, verwenden und schützen,
                    und gewährleisten größere Transparenz und Kontrolle über Ihre Informationen.
                    Ihre Zustimmung ist unerlässlich, damit wir unsere Dienste sicher und in Übereinstimmung mit den Datenschutzgesetzen weiterhin anbieten können.
                    Bitte lesen Sie die neuen Bedingungen sorgfältig durch und klicken Sie auf das Kontrollkästchen, um Ihre Zustimmung zu bestätigen.
                    Dieser Schritt ist zwingend erforderlich, damit wir unsere Dienste weiterhin nutzen können.
                    Wir danken Ihnen für Ihr Verständnis und Ihre Kooperation.
                  </>
                )}
              </p>
            </div>

            {!termsAlreadyAccepted && (
              <div className="flex items-center mt-6 w-full justify-center">
                <input
                  type="checkbox"
                  id="terms"
                  checked={acceptedTerms}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                <label htmlFor="terms">
                  {language === 'en' ? (
                    <>
                      I have read and accept the new <a href="#" style={{cursor: "pointer", color: "#1289A7 "}}>terms</a> of use and new <a href="#" style={{cursor: "pointer", color: "#1289A7 "}}>data</a> of use.
                    </>
                  ) : (
                    <>
                      Ich habe die neuen <a href="#" style={{cursor: "pointer", color: "#1289A7 "}}>Nutzungsbedingungen</a> und die neuen <a href="#" style={{cursor: "pointer", color: "#1289A7 "}}>Datenschutzbestimmungen</a> gelesen und akzeptiere sie.
                    </>
                  )}
                </label>
              </div>
            )}

            {termsAlreadyAccepted && (
              <div className="font-bold text-center mt-4">
                {language === 'en' ? (
                  <>You have already accepted the <a href="#" style={{cursor: "pointer", color: "#1289A7 "}}>terms</a> of use and new <a href="#" style={{cursor: "pointer", color: "#1289A7 "}}>data</a>.</>
                ) : (
                  <>Sie haben die <a href="#" style={{cursor: "pointer", color: "#1289A7 "}}>Nutzungsbedingungen</a> und die neuen <a href="#" style={{cursor: "pointer", color: "#1289A7 "}}>Datenschutzbestimmungen</a> bereits akzeptiert.</>
                )}
              </div>
            )}
          </div>

          {!termsAlreadyAccepted && (
            <div>
              <button
                type="submit"
                disabled={!acceptedTerms}
                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none ${acceptedTerms ? '' : 'opacity-50 cursor-not-allowed'}`}
                onClick={() => email ? emailService.acceptTerms(email).then(() => window.location.reload()) : null}
              >
                {language === 'en' ? 'Submit' : 'Absenden'}
              </button>
            </div>
          )}
        </div>
      </main>

      <footer className="flex items-center justify-center w-full shadow-2xl p-4">
        <p className="text-center">2024 All rights reserved.</p>
      </footer>

      <div className="fixed bottom-4 right-4">
        <button
          onClick={toggleLanguage}
          className="bg-gray-800 text-white font-bold py-2 px-4 rounded"
        >
          {language === 'en' ? 'Switch to German' : 'Switch to English'}
        </button>
      </div>
    </div>
  );
}

export default App;
